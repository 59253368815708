Body{
    background-color: #28262b;
    overflow:  hidden;
    
}
.altcolor{
    background-color: #28262b;
}

.color{
    background-color: #343a40;
    border: none;
}
.color:focus{
    outline: none;
    background-color: limegreen;
    box-shadow: none;
}

.margindown{
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}

.slide::-webkit-scrollbar {
    width: 0.5rem;
}

.devices{
    background-color: white!important;
    height: 20px;
    width: 100%;
    margin-bottom: 200px;
    border-radius: 50%;
}

.managesize{
    width: 50%;
    aspect-ratio: 1/1;
    transform: translate(100%, -75%)!important;
    border: 5px solid #28262b;
}

    @media screen and (max-width: 1170px) {
    .managesize{
        width: 150% !important;
        height: 150% !important;
        transform: translate(0%, -20%)!important;
        border: 1px solid #28262b;
    }

    .smallPlayer{
        margin-bottom: 55px !important;
    }
}

.ps{
    padding-left: 10px!important;
}

.mt{
    padding-top: 5px!important;;
}
